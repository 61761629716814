import type { AxiosResponse } from 'axios';
import axios from 'axios';

import type { ListingDocument } from '@/types/api/listing';

/**
 * Lives here temporarily
 * Will move when documents retrieval finalized in the API
 */
const http = axios.create({
  method: 'GET',
  responseType: 'blob',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  withCredentials: true,
  withXSRFToken: true,
});

const useFileHandler = () => {
  const base64ToBlob = (base64String: string, contentType: string) => {
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: contentType });
  };

  const makeDownloadableBlob = (fileTitle: string, responseData: AxiosResponse) => {
    const blob = new Blob([responseData.data], { type: responseData.headers['content-type'] });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileTitle;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const downloadDocument = (document: Pick<ListingDocument, 'url' | 'label'>) => {
    return http(document.url).then((res: AxiosResponse) => {
      makeDownloadableBlob(document.label, res);
    });
  };

  return {
    base64ToBlob,
    downloadDocument,
  };
};

export default useFileHandler;
