<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form f-auth-email"
    greedy
    @submit.prevent="onSubmit"
    @validation-error="onValidationError"
  >
    <FInput
      v-model.trim="formData.email"
      :disable="loading"
      :error="!!errors.email?.[0]"
      :error-message="errors.email?.[0]"
      :label="t('AuthEmail.fields.email.label')"
      lazy-rules
      name="email"
      outlined
      :rules="[requiredDefault, emailDefault]"
    />

    <RecaptchaDisclaimer />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import RecaptchaDisclaimer from '@/components/Reusable/RecaptchaDisclaimer.vue';
import { useApiAuth } from '@/composables/api/auth';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import translations from '@/i18n/translations/components/formSteps.json';
import type { ApiError } from '@/types/api';
import type { FNextAuth, FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: FNextAuth;
}>();

const emit = defineEmits<{
  (e: 'next', p: FNextAuth): void;
}>();

const { otp } = useApiAuth();
const { getToken } = useCaptcha();
const { t } = useI18n(translations);
const { elQForm, email, onValidationError, required } = useFormInputRules();

const requiredDefault = required();
const emailDefault = email();

const formData = reactive({ email: '' });
const errors = ref<Partial<Record<keyof typeof formData, string[]>>>({});
const loading = ref(false);

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value,
  form: `f-${props.formId}`,
  label: t('AuthEmail.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const onSubmit = async () => {
  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;
  errors.value = {};

  const rToken = await getToken('sendOtp');

  otp({ email: formData.email, r_token: rToken })
    .then(() => {
      emit('next', {
        ...props.next,
        email: formData.email,
        resCode: 200,
      });
    })
    .catch((error: ApiError<keyof typeof formData>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 417:
            emit('next', {
              email: formData.email,
              resCode: 417,
            });
            break;
          case 422:
            errors.value = response.data.errors;
            break;
          default:
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>
