<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form auth-register"
    greedy
    @submit.prevent="onSubmit"
    @validation-error="onValidationError"
  >
    <FInput
      v-model.trim="formData.first_name"
      :disable="loading"
      :error="!!errors.first_name?.[0]"
      :error-message="errors.first_name?.[0]"
      :label="t('AuthRegister.fields.firstName.label')"
      :placeholder="t('AuthRegister.fields.firstName.placeholder')"
      lazy-rules
      outlined
      :rules="[requiredDefault]"
    />

    <FInput
      v-model.trim="formData.last_name"
      :disable="loading"
      :error="!!errors.last_name?.[0]"
      :error-message="errors.last_name?.[0]"
      :label="t('AuthRegister.fields.lastName.label')"
      :placeholder="t('AuthRegister.fields.lastName.placeholder')"
      lazy-rules
      outlined
      :rules="[requiredDefault]"
    />

    <FInputPhone
      v-model="formData.phone"
      :disabled="loading"
      :error="!!errors.phone?.[0]"
      :error-message="errors.phone?.[0]"
      :label="t('AuthRegister.fields.phone.label')"
      outlined
      :rules="[requiredDefault]"
    />

    <FInputTerms
      v-model="formData.accepts_terms"
      class="q-mt-lg"
      :error-message="errors.accepts_terms?.[0]"
    />

    <FInputNewsletterOptIn v-model="formData.newsletterOptIn" color="primary" />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputNewsletterOptIn from '@/components/Form/input/FInputNewsletterOptIn.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import FInputTerms from '@/components/Form/input/FInputTerms.vue';
import { useApiAuth } from '@/composables/api/auth';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import useGTM from '@/composables/useGTM';
import translation from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';
import type { ApiError } from '@/types/api';
import type { FNextAuth, FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next: FNextAuth;
}>();

const emit = defineEmits<{
  (e: 'next', p: typeof props.next): void;
  (e: 'prev'): void;
}>();

const { register } = useApiAuth();

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const { getToken } = useCaptcha();
const { t } = useI18n(translation);

const { elQForm, onValidationError, required } = useFormInputRules();
const { user } = storeToRefs(useAuthStore());

const requiredDefault = required();

const loading = ref(false);

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value,
  form: `f-${props.formId}`,
  label: t('AuthRegister.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const formData = ref({
  first_name: '',
  last_name: '',
  phone: '',
  accepts_terms: false,
  newsletterOptIn: false,
});

const errors = ref<Partial<Record<keyof typeof formData.value, string[]>>>({});

const onSubmit = async () => {
  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;
  errors.value = {};

  const rToken = await getToken('signUp');

  register({
    r_token: rToken,
    first_name: formData.value.first_name,
    last_name: formData.value.last_name,
    phone: formData.value.phone,
    email: props.next.email || '',
    accepts_terms: formData.value.accepts_terms,
    newsletterOptIn: formData.value.newsletterOptIn,
  })
    .then(res => {
      useGTM('user_register_success');

      user.value = res.data.data;

      emit('next', { ...props.next, resCode: 201 });
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 403:
            emit('prev');
            break;
          case 422:
            errors.value = response.data.errors;
            break;
          default:
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>
