import type { FactoryId } from '@/types/formStepsFactory';
import type CommercialHotelListing from '@/viewModels/CommercialHotelListing';
import type CommercialListing from '@/viewModels/CommercialListing';
import type CommercialOfficeListing from '@/viewModels/CommercialOfficeListing';
import type LandListing from '@/viewModels/LandListing';
import type ResidentialListing from '@/viewModels/ResidentialListing';

export type Listing =
  | ResidentialListing
  | LandListing
  | CommercialHotelListing
  | CommercialOfficeListing
  | CommercialListing;

export enum CommercializationType {
  SALE = 'sale',
  RENTAL = 'rental',
  AUCTION = 'auction',
}

export enum ListingCategory {
  ALL = 'all',
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
  LAND = 'land',
}

export type ListingType =
  | 'apartment'
  | 'blockOfFlats'
  | 'complexOfVillasMaisonettes'
  | 'detached'
  | 'floorApartment'
  | 'hotel'
  | 'industrial'
  | 'loft'
  | 'maisonette'
  | 'mixedUse'
  | 'offices'
  | 'parking'
  | 'retail'
  | 'studio'
  | 'villa';

export enum ListingCompletionStatus {
  COMPLETED = 'completed',
  INCOMPLETE = 'incomplete',
  PARTIALLY_COMPLETED = 'partiallyCompleted',
  UNDER_CONSTRUCTION = 'underConstruction',
}

export type TCan =
  | 'acquired'
  | 'agent'
  | 'auction'
  | 'auction-to-be-announced'
  | 'auction-next-round'
  | 'auction-rounds'
  | 'auction-siblings'
  | 'documents'
  | 'download-pdf'
  | 'extra-info'
  | 'financial'
  | 'help'
  | 'location'
  | 'lp-offer-expired'
  | 'lro'
  | 'multi-unit'
  | 'new'
  | 'price-reserved'
  | 'price-upon-request'
  | 'renovation'
  | 'rental'
  | 'revenues'
  | 'running-costs'
  | 'sale'
  | 'similar'
  | 'underOffer'
  | FactoryId
  // | CommercializationType
  | ListingCategory
  // | 'residential'
  // | 'commercial'
  // | 'land'
  | ListingType;

export enum CustomerType {
  INDIVIDUAL = 'Individual',
  COMPANY = 'Company',
}

export type ListingExtraInfoSchemaItemProperties = {
  type: string;
  path: string;
  unit?: string;
};
export type ListingExtraInfoSchemaItem = {
  label: string;
  icon?: string;
  properties: {
    [key: string]: ListingExtraInfoSchemaItemProperties;
  };
};
export type ListingExtraInfoSectionPropertiesItem = {
  label: string;
  value: number | string | null;
  key?: string;
};
export type ListingExtraInfoSection = {
  label: string;
  icon?: string;
  properties: ListingExtraInfoSectionPropertiesItem[];
};

export type ListingAmenitiesSchemaItem = {
  icon: string;
  multiLevel: boolean;
  multiUnit: boolean;
  ordinal: boolean;
  path: string;
  type: string;
  suffix: string;
  unit: string;
};

export type ListingExtraInfo = {
  sections: ListingExtraInfoSection[];
  description: string;
  units?: Array<ListingExtraInfoSectionPropertiesItem[]>;
};

export enum ListingImageOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export type ListingImages = {
  sizes: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
  };
  id: number;
  title: string;
  orientation: ListingImageOrientation;
  description: string | null;
  name: string | null;
  collection: 'floorPlans' | 'photos' | 'mapPhotos' | 'matterportVideo';
};

export type ListingExternalMedia = {
  type: 'matterport' | 'drone-video';
  url: string;
};

export type ListingImageCollections = {
  photos: ListingImages[];
  mapPhotos?: ListingImages[];
  floorPlans: ListingImages[];
};

export enum ListingAvailability {
  Available = 'available',
  UnderOffer = 'underOffer',
  OfferAccepted = 'offerAccepted',
  InCloseTenderProcess = 'inCloseTenderProcess',
  Rented = 'rented',
  Sold = 'sold',
  Unavailable = 'unavailable',
}

export interface AvailabilityLabelMap {
  [key: string]: ListingAvailability;
}
