import { computed } from 'vue';

import type { FactoryId, FormStepsFactory } from '@/types/formStepsFactory';

export const useStepsLpOpenDay = () => {
  const factoryId: FactoryId = 'unsupported';

  const getFormStepsLpOpenDay = (): FormStepsFactory => computed(() => []);

  return { factoryId, getFormStepsLpOpenDay };
};
