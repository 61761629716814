import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormMortgage from '@/components/ListingPage/Form/LPFormMortgage.vue';
import { useApiListing } from '@/composables/api/listing';
import { useCaptcha } from '@/composables/useCaptcha';
import useGTM from '@/composables/useGTM';
import { useUtmSource } from '@/composables/useUtmSource';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import useAuthStore from '@/store/modules/auth';
import useListingStore from '@/store/modules/listing';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsLpMortgage = () => {
  const factoryId: FactoryId = 'lp-mortgage';

  const { storeMortgage } = useApiListing();
  const { dialogAuth, dialogFactoryId, user } = storeToRefs(useAuthStore());
  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpMortgage = (): FormStepsFactory => {
    const { listing } = storeToRefs(useListingStore());

    const submit = async () => {
      if (!listing.value) throw new Error('Listing is undefined');

      const { resolveUtmSource } = useUtmSource();
      const { getToken } = useCaptcha();

      const rToken = await getToken('submitMortgage');

      return storeMortgage({
        listingId: listing.value.id,
        utmSource: resolveUtmSource(),
        r_token: rToken,
      }).then(() => {
        useGTM('request_mortgage_sale');
      });
    };

    return computed<Step[]>(() => [
      {
        component: () => LPFormMortgage,
        formId: 'lp-mortgage',
        onNextCallback: (next, state) => {
          if (!user.value) {
            dialogFactoryId.value = factoryId;
            dialogAuth.value = true;

            watch(
              dialogAuth,
              () => {
                if (user.value) {
                  state.onNext(next);
                }
              },
              { once: true }
            );
          } else {
            state.onNext(next);
          }
        },
        subtitle: t('subtitle.mortgageAuction'),
        title: t('title.mortgageAuction'),
      },
      {
        component: () => FormStepperCallback,
        componentProps: {
          onMountedCallbackFn: submit,
          formInfoMsgPropsError: {
            subtitle: { text: t('subtitle.mortgageError') },
            title: { text: t('title.mortgageError') },
          },
          formInfoMsgPropsSuccess: {
            subtitle: { text: t('subtitle.mortgageSuccess') },
            title: { text: t('title.mortgageSuccess') },
          },
        },
        formId: 'callback',
      },
    ]);
  };

  return { factoryId, getFormStepsLpMortgage };
};
