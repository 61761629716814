import { watch } from 'vue';

import { useSearchPage } from '@/composables/searchPage';
import useSearchStore from '@/store/modules/search';
import useThemeStore from '@/store/modules/theme';

export const useVarPool = () => {
  const searchPage = useSearchPage();
  const themeStore = useThemeStore();
  const searchStore = useSearchStore();

  // Scroll to top after fetching results
  watch(
    () => searchStore.loadingState,
    v => {
      if (!v) {
        setTimeout(() => {
          themeStore.elPageSearchList?.scrollTo({ top: 0 });
        }, 100);
      }
    }
  );

  return { ...searchPage, themeStore };
};
