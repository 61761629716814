import type {
  ListingAuction,
  ListingAuctionRound,
  ListingAuctionSiblings,
} from '@/types/api/listing';
import { isBeforeDate } from '@/utils/time';

export default class Auction {
  public readonly dateToBeAnnounced: boolean;

  public readonly nextRoundComingSoon: boolean;

  private readonly auctionRounds: ListingAuctionRound[];

  private readonly auctionSiblings?: ListingAuctionSiblings[];

  constructor(auction: ListingAuction) {
    this.auctionRounds = auction.rounds;
    this.auctionSiblings = auction.siblings;
    this.dateToBeAnnounced = auction.dateToBeAnnounced;
    this.nextRoundComingSoon = auction.nextRoundComingSoon || false;
  }

  public get rounds(): ListingAuctionRound[] {
    if (this.auctionRounds.length === 0) return [];

    const rounds = JSON.parse(JSON.stringify(this.auctionRounds));

    const sortedRounds = rounds.sort(
      (a: ListingAuctionRound, b: ListingAuctionRound) => a.number - b.number
    );

    return sortedRounds;
  }

  public get currentRound(): ListingAuctionRound {
    return this.rounds[this.rounds.length - 1];
  }

  public get firstRound(): ListingAuctionRound {
    return this.rounds[0];
  }

  public get originalPrice(): number {
    return this.firstRound.startingPrice;
  }

  public get price(): number {
    return this.currentRound.startingPrice;
  }

  public get siblings() {
    return this.auctionSiblings;
  }

  public auctionDatePassed(): boolean {
    return isBeforeDate(this.currentRound.auctionDate, Date(), 'day');
  }

  public auctionDate(): string {
    return this.currentRound.auctionDate;
  }
}
