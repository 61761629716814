<template>
  <div
    :class="{ 'f-input f-input-phone': true, 'f-input--has-errors': !!errorMessage }"
    :required="qInputProps.required"
  >
    <div class="f-input__label" v-text="label" />

    <q-field
      class="f-input-phone__field"
      :disable="qInputProps.disabled"
      :error="qInputProps.error"
      :error-message="errorMessage"
      :model-value="modelValue"
      no-error-icon
      :outlined="outlined"
      :rules="rulesLocal"
      lazy-rules
    >
      <template #control>
        <VueTelInput
          ref="elVueTelInput"
          v-model="modelValue"
          v-bind="{ ...qInputProps, ...$attrs }"
          :auto-default-country="false"
          :class="{
            'vue-tel-input': true,
            'vue-tel-input--focused': focused,
            'vue-tel-input--opened': opened,
          }"
          :default-country="configCountry?.code"
          :dropdown-options="{
            showDialCodeInSelection: false,
            showFlags: true,
          }"
          :input-options="{ placeholder }"
          mode="international"
          style-classes="vue-tel-input__wrapper"
          valid-characters-only
          @blur="onBlur"
          @close="onClose"
          @country-changed="onCountryChanged($event?.dialCode)"
          @focus="onFocus"
          @open="onOpen"
          @validate="onValidate($event)"
        >
          <template #arrow-icon>
            <q-icon :class="{ 'rotate-180': opened }" name="arrow_drop_down" size="1.5rem" />
          </template>
        </VueTelInput>
      </template>
    </q-field>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue';
import { VueTelInput } from 'vue-tel-input';

import { useFormInputRules } from '@/composables/formInputRules';
import useAppStore from '@/store/modules/app';
import type { FInputPhoneProps } from '@/types/formInput';

const props = defineProps<FInputPhoneProps>();

const { configCountry } = storeToRefs(useAppStore());

const focused = ref(false);
const opened = ref(false);
const prefixDialCode = ref('');

export type ValidationEvent = {
  country?: string | undefined;
  countryCode?: string | undefined;
  formatted?: string | undefined;
  valid?: boolean | undefined;
  possible?: boolean | undefined;
  nationalNumber?: string | undefined;
};

const isValid = ref(false);
const modelValue = defineModel<string | null>('modelValue');

const elVueTelInput = useTemplateRef<InstanceType<typeof VueTelInput>>('elVueTelInput');
const elVueTelInputBoundingWidth = ref('400px');

const qInputProps = computed(() => {
  const p = { ...props };

  delete p.errorMessage;
  delete p.label;
  delete p.rules;

  // hack to fix invalid package type definitions
  if (!Array.isArray(p.preferredCountries)) {
    p.preferredCountries = [];
  }

  return p;
});

const { phone } = useFormInputRules();

const phoneDefault = phone();

const rulesLocal = computed(() => [...(props.rules || []), () => phoneDefault(isValid.value)]);

const onCountryChanged = (dialCode?: string) => {
  if (dialCode) prefixDialCode.value = `+${dialCode}`;
};

const formatModelValue = () => {
  if (modelValue.value && !modelValue.value.startsWith(prefixDialCode.value)) {
    modelValue.value = `${prefixDialCode.value} ${modelValue.value.replace('+', '')}`;
  }
};

const onFocus = () => {
  const el = document.querySelector('.vue-tel-input');
  elVueTelInputBoundingWidth.value = `${el!.clientWidth}px`;
  focused.value = true;
};

const onBlur = () => {
  focused.value = false;
  formatModelValue();
};

const onOpen = () => {
  opened.value = true;
};

const onClose = () => {
  opened.value = false;
};

const onValidate = ($event: ValidationEvent) => {
  isValid.value = $event.valid || false;
};

watch([opened, () => Screen.width], v => {
  if (v[0]) {
    nextTick(() => {
      const el = document.querySelector('.vue-tel-input--opened');

      if (el) {
        elVueTelInputBoundingWidth.value = `${el.clientWidth}px`;
      }
    });
  }
});
</script>

<style lang="scss">
@use 'sass:map';
@use 'vue-tel-input/vue-tel-input.css';

.vue-tel-input__wrapper {
  width: 100%;
  height: 3.5rem;
  border-color: $util-2;
  border-radius: map.get($radius-sizes, 'sm');
}

.vti__selection .q-icon {
  transition: transform 0.28s;
}

.vue-tel-input__wrapper .vti__input {
  font-weight: 600;
  border-radius: 0 map.get($radius-sizes, 'sm') map.get($radius-sizes, 'sm') 0;
}

.vue-tel-input--focused {
  border-color: $secondary;
  border-width: 0.125rem;
}

.vue-tel-input__wrapper .vti__dropdown:hover,
.vue-tel-input__wrapper .vti__dropdown.disabled,
.vue-tel-input__wrapper .vti__dropdown.open {
  background-color: transparent;
  border-radius: map.get($radius-sizes, 'sm') 0 0 map.get($radius-sizes, 'sm');
}

.vue-tel-input__wrapper .vti__dropdown-list.below {
  top: auto;
  bottom: 100%;
}

.vue-tel-input__wrapper .vti__dropdown-list {
  width: v-bind('elVueTelInputBoundingWidth');
  margin-bottom: 0.375rem;
  border: none;
  border: 1px solid $util-2;
  border-radius: map.get($radius-sizes, 'sm');
  box-shadow: 0 4px 8px rgb(219 225 245 / 25%);
}

.vue-tel-input__wrapper .vti__dropdown-item {
  min-height: 3rem;
  padding: 0.75rem 1rem;
}

.vue-tel-input__wrapper .vti__dropdown-item.highlighted {
  background-color: transparent;
}

.vue-tel-input__wrapper:focus-within {
  border-color: $secondary;
  box-shadow: none;
}

.f-input--has-errors {
  .vue-tel-input,
  .vue-tel-input--focused {
    border-color: var(--q-negative);
  }
}

.f-input-phone {
  .button:focus-visible,
  input:focus-visible {
    outline: none;
  }

  .q-field__native,
  .q-field--outlined .q-field__control {
    padding: 0;
  }

  .q-field__bottom {
    padding: 0.5rem 0.75rem 0;
  }

  .q-field--standard .q-field__control:before {
    display: none;
  }

  .q-field--standard .q-field__control:after {
    display: none;
  }
}
</style>
