import { useElementBounding } from '@vueuse/core';
import camelCase from 'lodash/camelCase';
import { format, type QCard, type QIcon } from 'quasar';
import { computed, type HTMLAttributes, reactive, shallowRef, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { useTheme } from '@/composables/theme';
import { useFavorites } from '@/composables/useFavorites';
import useListingAvailability from '@/composables/useListingAvailability';
import useLocalizedLinks from '@/composables/useLocalizedLinks';
import configApp from '@/config/app.json';
import { CommercializationType } from '@/types';
import { humanReadableLabel } from '@/utils/format';
import { capitalize, capitalizeSentence, formatLabel, replaceAccentedVowels } from '@/utils/string';
import { toDateTimeFormat } from '@/utils/time';
import type Listing from '@/viewModels/Listing';

export interface SearchResultsItemProps {
  class?: HTMLAttributes['class'];
  listing: Listing;
}

export const useSearchResultsItem = (_props: SearchResultsItemProps) => {
  const props = toRef(_props);

  const listingLocal = computed(() => props.value.listing);

  const photosMerged = computed(() => [
    ...listingLocal.value.images.photos,
    ...(listingLocal.value.images?.mapPhotos || []),
  ]);

  const { formatPrice, formatPropertyCode, getComponentConfig } = useTheme();

  const { elListingItemFavoritesBtnBoundingsWidth, isFavorite: isListingFavorite } = useFavorites();

  const { newListingThresholdDays, squareMeterPriceVisible, vatablePriceVisible } = configApp;

  const { CtaWidget: config } = getComponentConfig();

  const { t: tGlobal, locale } = useI18n();

  const auctionCurrentRoundNumber = listingLocal.value.auction?.currentRound.number;

  const getFloorsText = (shortText: boolean) => {
    let floorText = '';

    const { floors } = listingLocal.value.aggregations;

    const floorsLength = floors.length;

    if (floorsLength > 0) {
      if (floorsLength > 1) {
        floorText = `${floorsLength} ${format.capitalize(tGlobal('level', 2))}`;
      } else {
        floorText = `${humanReadableLabel(
          'floor',
          floors[0],
          'object',
          null,
          shortText ? 'short' : 'long'
        )}`;
      }
    }

    return floorText;
  };

  const ownershipData =
    listingLocal.value.isAuction() && listingLocal.value.ownership?.fullOwnership !== null
      ? listingLocal.value.ownership
      : null;

  const ownershipText = computed(() =>
    !!ownershipData?.coOwnershipRight && !!ownershipData?.coOwnershipShare
      ? `${ownershipData.coOwnershipShare}% ${tGlobal(ownershipData.coOwnershipRight)}`
      : ''
  );

  const ownershipTextVisible = computed(
    () => !!(ownershipData?.coOwnershipRight && ownershipData?.coOwnershipShare)
  );

  const listingHasLabel = (label: Listing['labels'][number]) =>
    listingLocal.value.labels.includes(label);

  const isVip = computed(
    () => listingHasLabel('vip') && !listingLocal.value.listingInfo.statuses.acquired
  );

  const isFavorite = computed(() => isListingFavorite(listingLocal.value.id));

  const locations = computed(() => {
    const { locations: listingLocations } = listingLocal.value.address;

    return capitalizeSentence(
      listingLocations[listingLocations.length - 1].replace(`${listingLocations[0]},`, '')
    );
  });

  const title = computed(
    () =>
      listingLocal.value.listingInfo.title ||
      `${formatLabel(camelCase(listingLocal.value.type))}, ${locations.value}`
  );

  const auctionDate = computed(() =>
    listingLocal.value.auction
      ? toDateTimeFormat(
          listingLocal.value.auction.auctionDate(),
          config.auctionRound.dateFormat,
          locale.value
        )
      : ''
  );

  const auctionDatePassed =
    listingLocal.value.auction === null ? true : listingLocal.value.auction.auctionDatePassed();

  const auctionDateMasked = auctionDatePassed && !!listingLocal.value.auction?.nextRoundComingSoon;

  const priceFormatted = formatPrice(listingLocal.value.price);

  const propertyCodeFormatted = formatPropertyCode(listingLocal.value.propertyCode);

  const pricePerSqMeter =
    listingLocal.value.size > 0
      ? formatPrice(listingLocal.value.price / listingLocal.value.size)
      : '';

  const priceUponRequestText = capitalize(tGlobal('priceUponRequest'));

  const listingAuctionRounds =
    !listingLocal.value.isAuction() || !listingLocal.value.auction
      ? []
      : listingLocal.value.auction.rounds;

  const auctionDateOrRoundVisible =
    listingAuctionRounds.length > 0 && !listingLocal.value.listingInfo.statuses.acquired;

  const isPriceChanged = listingLocal.value.priceDiff() !== 0;

  const parkingSpaces = listingLocal.value.aggregations.parkingSpaces || 0;

  const bedrooms = listingLocal.value.aggregations.bedrooms || 0;

  const newlyListedVisible = listingLocal.value.isNewlyCreated(newListingThresholdDays);

  const { listingLocalizedLink } = useLocalizedLinks();

  const hrefListingDetails = computed(() => listingLocalizedLink(listingLocal.value.id));

  const elListingItem = shallowRef<InstanceType<typeof QCard> | null>(null);

  const elListingItemBounding = reactive(useElementBounding(elListingItem));

  const elListingItemVipBadge = shallowRef<InstanceType<typeof QIcon> | null>(null);

  const elListingItemVipBadgeBoundings = reactive(useElementBounding(elListingItemVipBadge));

  const elListingItemVipBadgeBoundingsWidth = computed(
    () => `${elListingItemVipBadgeBoundings.width}px`
  );

  const elListingItemFavoritesBtnWidth = computed(
    () => `${elListingItemFavoritesBtnBoundingsWidth.value}px`
  );

  const elListingItemHasClass = (token: string) =>
    elListingItem.value?.$el.classList.contains(token);

  const siblingsValue = computed(() => {
    if (
      !listingLocal.value.auction ||
      ['parking', 'storage'].includes(listingLocal.value.type) ||
      listingLocal.value.commercializationType !== CommercializationType.AUCTION
    )
      return '';

    const { siblings } = listingLocal.value.auction;

    const value = [];

    if (siblings?.findIndex(s => s.type === 'storage') !== -1) {
      value.push(capitalize(tGlobal('storage')));
    }

    if (siblings?.findIndex(s => s.type === 'parking') !== -1) {
      value.push(capitalize(tGlobal('parking')));
    }

    return value.join(' & ');
  });

  const isAcquired = computed(() => listingLocal.value.listingInfo.statuses.acquired);

  const { availabilityStatus } = listingLocal.value.listingInfo.statuses;

  const { commercializationType } = listingLocal.value;

  const badgesVisible = !isAcquired.value && photosMerged.value.length > 0;

  const imgRibbonLabel = computed(() =>
    replaceAccentedVowels(
      tGlobal(
        useListingAvailability().listingAvailabilityLabel(availabilityStatus, commercializationType)
      )
    )
  );

  const hotelRooms = computed(() =>
    Number(listingLocal.value.amenities.find(obj => obj.name === 'hotelRooms')?.value || 0)
  );

  const vatText = computed(() => {
    const { vatType } = listingLocal.value.listingInfo.price;
    if (vatType === 'none') return '';

    return `+${replaceAccentedVowels(tGlobal(`vat${capitalize(vatType)}`))}`;
  });

  return {
    auctionCurrentRoundNumber,
    auctionDate,
    auctionDateMasked,
    auctionDateOrRoundVisible,
    auctionDatePassed,
    badgesVisible,
    bedrooms,
    elListingItem,
    elListingItemBounding,
    elListingItemHasClass,
    elListingItemVipBadge,
    elListingItemVipBadgeBoundingsWidth,
    elListingItemFavoritesBtnWidth,
    getFloorsText,
    hotelRooms,
    hrefListingDetails,
    isPriceChanged,
    isVip,
    isFavorite,
    listingHasLabel,
    listingLocal,
    locations,
    newlyListedVisible,
    ownershipData,
    ownershipText,
    ownershipTextVisible,
    parkingSpaces,
    priceFormatted,
    pricePerSqMeter,
    priceUponRequestText,
    propertyCodeFormatted,
    photosMerged,
    imgRibbonLabel,
    siblingsValue,
    squareMeterPriceVisible,
    vatablePriceVisible,
    vatText,
    title,
    tGlobal,
    isAcquired,
    availabilityStatus,
    commercializationType,
  };
};
