<template>
  <div class="f-input-currency">
    <div class="f-input__label" v-text="label" />

    <q-input ref="inputRef" v-bind="qInputProps" v-model="formattedValueLocal.value" no-error-icon>
      <template #append>
        <span class="text-secondary text-body2" v-text="'&euro;'" />
      </template>
    </q-input>
  </div>
</template>

<script setup lang="ts">
import { reactiveComputed } from '@vueuse/core';
import { computed, watch } from 'vue';
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input';

import type { FInputCurrencyProps } from '@/types/formInput';

const props = withDefaults(defineProps<FInputCurrencyProps>(), {
  options: () => ({
    autoDecimalDigits: true,
    currency: 'EUR',
    currencyDisplay: CurrencyDisplay.hidden,
    hideGroupingSeparatorOnFocus: false,
    locale: 'de-DE',
    precision: 0,
  }),
});

defineEmits<{
  (e: 'change', p: number): void;
  (e: 'update:modelValue', p: number | null): void;
}>();

const qInputProps = computed(() => {
  const p: Partial<FInputCurrencyProps> = { ...props };

  delete p.label;
  delete p.options;

  return p;
});

const { formattedValue, inputRef, setOptions, setValue } = useCurrencyInput(props.options);

const formattedValueLocal = reactiveComputed(() => {
  return {
    value: formattedValue.value || '',
  };
});

watch(
  () => props.modelValue,
  v => {
    setValue(v);
  }
);

watch(
  () => props.options,
  v => {
    setOptions(v);
  }
);
</script>
