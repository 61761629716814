import _get from 'lodash/get';

import type {
  ListingExtraInfoSchemaItemProperties,
  ListingExtraInfoSectionPropertiesItem,
} from '@/types';
import type { ListingUnit } from '@/types/api/listing';

import { humanReadableLabel } from '../format';
import { formatLabel } from '../string';

export default class UnitInfoBuilder {
  private _units: ListingUnit[];

  private _schema!: Record<string, ListingExtraInfoSchemaItemProperties>;

  constructor(units: ListingUnit[]) {
    this._units = units;
  }

  public set schema(schema: Record<string, ListingExtraInfoSchemaItemProperties>) {
    this._schema = schema;
  }

  public build(): Array<ListingExtraInfoSectionPropertiesItem[]> {
    if (!this._schema) throw new Error('Unit schema is not set');

    const units: Array<ListingExtraInfoSectionPropertiesItem[]> = [];

    this._units.forEach(unit => {
      const tmpl: ListingExtraInfoSectionPropertiesItem[] = [];

      Object.keys(this._schema).forEach(key => {
        const { path } = this._schema[key];

        const obj = {
          key,
          label: formatLabel(key),
          value: humanReadableLabel(
            key,
            _get(unit, path, null),
            this._schema[key].type,
            this._schema[key]?.unit
          ),
        };

        if (obj.value !== null && obj.value !== '') tmpl.push(obj);
      });

      if (tmpl.length > 0) units.push(tmpl);
    });

    return units;
  }
}
